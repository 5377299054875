import { useMemo } from "react"
import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { isBrowser } from "../utils/common"
import cacheConfig from "./cacheConfig"

let apolloClient: ApolloClient<NormalizedCacheObject>
let token: string | null = null

export const clearToken = () => {
  token = null
}

const authLink = setContext((req, args) => {
  const { headers } = args
  if (isBrowser()) {
    if (token)
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    return new Promise((resolve) => {
      fetch("/api/auth").then((response) =>
        response.json().then(({ status, access_token }) => {
          if (status) {
            token = access_token
            resolve({
              headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : "",
              },
            })
          } else {
            resolve({
              headers: {
                ...headers,
              },
            })
          }
        })
      )
    })
  }

  return { headers }
})

export function createApolloClient(headerOptions?: any) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(
      createHttpLink({
        uri: typeof window === "undefined" ? process.env.GRAPHQL_URL_SSR : process.env.GRAPHQL_URL_CSR,
        headers: {
          ...headerOptions,
        },
      })
    ),
    cache: new InMemoryCache({ ...cacheConfig }),
  })
}

export function initializeApollo(initialState: any = null, headerOptions?: any) {
  const _apolloClient = apolloClient ?? createApolloClient(headerOptions)

  if (initialState) {
    const existingCache = _apolloClient.extract()

    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  if (typeof window === "undefined") return _apolloClient

  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}

export function useApollo(initialState: any, headerOptions?: any) {
  const store = useMemo(() => initializeApollo(initialState, headerOptions), [initialState])
  return store
}
