import { gql } from "@apollo/client";
import { GALLERY_FRAGMENT } from "../Gallery";

export const COUNTRY_FRAGMENT = gql`
  fragment Country on Country {
    id
    name
  }
`;

export const LOCATION_FRAGMENT = gql`
  fragment CompanyLocation on CompanyLocation {
    id
    name
    country {
      ...Country
    }
    countries_id
    first_level_administrative_divisions_id
    second_level_administrative_divisions_id
    first {
      id
      name
    }
    second {
      id
      name
    }
    is_main
    address
    galleries {
      ...Gallery
    }
    longitude
    latitude
  }
  ${COUNTRY_FRAGMENT}
  ${GALLERY_FRAGMENT}
`;

export const LIMITED_MEMBER_FRAGMENT = gql`
  fragment MemberData on MemberData {
    id
    name
    gender
    summary
    image
    visibility_id
    user_id
    premium_member
    profile_completion {
      email_verify
      phone_verify
    }
    visibility {
      id
      name
    }
    image_file {
      path
      default
      downloadable
      sizes {
        large
        medium
        small
      }
    }
    work_experiences {
      id
      title
      until_now
      date_started
      date_ended
    }
    skills {
      id
      skill {
        id
        name
      }
      proviciency {
        id
        name
      }
    }
  }
`;

export const LIMITED_USER_FRAGMENT = gql`
  fragment UserData on UserData {
    id
    name
    member {
      ...MemberData
    }
  }
  ${LIMITED_MEMBER_FRAGMENT}
`;

export const LIMITED_COMPANY_FRAGMENT = gql`
  fragment CompanyData on CompanyData {
    id
    name
    descriptions
    logo
    banner
    verified
    employers_id
    map_lat
    map_lng
    about
    address
    status
    logo_file {
      sizes {
        small
      }
    }
    extended_info {
      id
      company_id
      phone
      email
      email_hrd
      industry_types_id
      industry_types {
        id
        name
      }
      industry_fields_id
      industry_fields {
        id
        name
      }
    }
  }
`;

export const LIMITED_EMPLOYER_FRAGMENT = gql`
  fragment EmployerData on EmployerData {
    id
    name
    image_serve {
      sizes {
        small
      }
    }
    company {
      ...CompanyData
    }
  }
  ${LIMITED_COMPANY_FRAGMENT}
`;

export const LIMITED_KITAKERJA_USER_FRAGMENT = gql`
  fragment KitakerjaUserData on KitakerjaUserData {
    id
    user_type
    user_id
    is_private
    is_premium
    is_followed
    follower_count
    following_count
    user {
      ...UserData
    }
    employer {
      ...EmployerData
    }
  }
  ${LIMITED_USER_FRAGMENT}
  ${LIMITED_EMPLOYER_FRAGMENT}
`;

export const GET_COMPANY_FRAGMENT = gql`
  fragment GetCompanyData on CompanyData {
    id
    employers_id
    name
    about
    address
    logo_file {
      default
      path
      sizes {
        small
      }
    }
    banner_file {
      path
      default
    }
    location {
      ...CompanyLocation
    }
    cloudtalent_id
    status
    verified
    map_lat
    map_lng
  }
  ${LOCATION_FRAGMENT}
`;

export const COMPANY_FRAGMENT = gql`
  fragment Company on Company {
    id
    employers_id
    size
    name
    about
    address
    logo_file {
      default
      path
      sizes {
        small
      }
    }
    banner_file {
      path
      default
    }
    location {
      ...CompanyLocation
    }
    cloudtalent_id
    status
    verified
    map_lat
    map_lng
  }
  ${LOCATION_FRAGMENT}
`;

export const PREMIUM_PACKAGE_HISTORY_FRAGMENT = gql`
  fragment PremiumPackageHistoryPagination on PremiumPackageHistoryPagination {
    nodes {
      id
      premium_package {
        id
        name
        price
      }
      premium_package_transaction {
        id
        kitakerja_user_id
        premium_package_id
        premium_package_history_id
        status
        amount
        payment_url
        payment_method
        upload_payment_image
        midtrans_order_id
        midtrans_token
        expired_at
        created_at
        updated_at
      }
    }
  }
`;
