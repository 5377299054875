
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "../styles/globals.scss";
import Router from "next/router";
import App from "next/app";
import type { AppContext, AppProps } from "next/app";
import StateProvider from "../context";
import { parse } from "cookie";
import { createApolloClient } from "../apollo";
import { GET_USER_DATA } from "../graphql/User";
import { useEffect } from "react";
import Layout from "../components/layout";
import Script from "next/script";
import { isBrowser } from "../utils/common";
import Head from "next/head";

interface InsidePagePropsProps {
  invalidToken: boolean;
  error: any;
  initialApolloState: any;
}

interface PagePropsProps {
  pageProps: InsidePagePropsProps;
}

function MyApp({ Component, pageProps }: AppProps & PagePropsProps) {
  useEffect(() => {
    if (pageProps?.invalidToken) {
      Router.replace("/sign-out");
    }
  }, [pageProps]);

  return (
    // <>
    //   <Head>
    //     {isBrowser() && process.env.NODE_ENV === "production" ? (
    //       <>
    //         <Script
    //           src="https://www.googletagmanager.com/gtag/js?id=G-E9M4143KL3"
    //           strategy="afterInteractive"
    //         />
    //         <Script id="google-analytics" strategy="afterInteractive">
    //           {`
    //       window.dataLayer = window.dataLayer || [];
    //       function gtag(){window.dataLayer.push(arguments);}
    //       gtag('js', new Date());

    //       gtag('config', 'G-E9M4143KL3');
    //     `}
    //         </Script>
    //       </>
    //     ) : (
    //       <></>
    //     )}
    //   </Head>

    <StateProvider>
      <Layout
        isError={pageProps?.error}
        initialApolloState={pageProps?.initialApolloState}
      >
        <Component {...pageProps} />
      </Layout>
    </StateProvider>
    // </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const { ctx } = appContext;

  const { req } = ctx;

  if (req?.headers.cookie) {
    const accessToken = parse(req.headers.cookie).access_token;

    if (accessToken) {
      try {
        const client = createApolloClient({
          authorization: `Bearer ${accessToken}`,
        });

        const result = await client.query({ query: GET_USER_DATA });

        if (result?.data?.me?.user_type === "User") {
          return {
            ...appProps,
            pageProps: {
              invalidToken: true,
            },
          };
        }

        return {
          ...appProps,
          pageProps: {
            ...appProps.pageProps,
            initialApolloState: client.cache.extract(),
          },
        };
      } catch (error: any) {
        console.error(JSON.stringify(error));
        if (error?.message === "record not found") {
          return {
            ...appProps,
            pageProps: {
              ...appProps,
              invalidToken: true,
            },
          };
        }
        if (
          error?.networkError?.result?.errors?.some(
            (err: any) =>
              err?.message === "Context creation failed: Invalid Token"
          )
        ) {
          return {
            ...appProps,
            pageProps: {
              ...appProps,
              invalidToken: true,
            },
          };
        }
        if (
          error?.networkError?.result?.errors?.some(
            (err: any) =>
              err?.message === "Context creation failed: Token Expired"
          )
        ) {
          return {
            ...appProps,
            pageProps: {
              ...appProps,
              invalidToken: true,
            },
          };
        }
        // if(error?.graphQLErrors?.result?.error){
        //   return {
        //     ...appProps,
        //     pageProps: {
        //       ...appProps,
        //       invalidToken: true,
        //     }
        //   }
        // }
        return {
          ...appProps,
          pageProps: {
            error: true,
          },
        };
      }
    }
  }

  return { ...appProps };
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  