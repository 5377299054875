import posthogjs from 'posthog-js';
import { useEffect, useState, createContext, Dispatch, SetStateAction, FC, useContext } from 'react';
import { isBrowser } from './common'

const PostHostContext = createContext<[typeof posthogjs | null, Dispatch<SetStateAction<typeof posthogjs | null>>]>([null, () => { }]);

interface PostHogProviderProps {
	children: React.ReactNode;
  }

export const PostHogProvider: FC <PostHogProviderProps> = ({ children }) => {
	const [posthog, setPosthog] = useState<typeof posthogjs | null>(null)

	useEffect(() => {
		if (isBrowser() && !posthog && process.env.NODE_ENV === 'production') {
			posthogjs.init(
				process.env.POSTHOG_API_KEY || '',
				{
					api_host: process.env.POSTHOG_API_URL,
					loaded: (posthog) => {
						setPosthog(posthog)
					},
					autocapture: false,
					capture_pageview: false,
					disable_session_recording: true,
				})
		}
	}, []);

	return (
		<PostHostContext.Provider value={[posthog, setPosthog]}>
			{children}
		</PostHostContext.Provider>
	)
}

const usePosthog = () => {
	const [posthog] = useContext(PostHostContext)

	function identify(id = "", { email = "" }) {
		if (posthog) {
			posthog.identify(id, { email })
		}
	}

	function getDistinctId() {
		if (posthog) return posthog.get_distinct_id();

		return null;
	}

	function reset() {
		posthog?.reset(true);
	}

	function captureOffer(bidder_kitakerja_user_id: number) {
		if (posthog) {
			posthog.capture("job_offer", { bidder_kitakerja_user_id });
		}
	}

	function captureJobApplicants({ applicationId = '', applierId = '', status = '' }) {
		if (posthog) {
			posthog.capture("job_applicants", { application_id: applicationId, applier_member_id: applierId, status });
		}
	}

	return {
		captureOffer,
		captureJobApplicants,
		identify,
		getDistinctId,
		reset,
	};
}

export default usePosthog;
