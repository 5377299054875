import axios from "axios";

const SERVER_DOMAIN = process.env.UPLOAD_URL;

export const uploadFile: any = (value: any, callback: any) =>
  new Promise((resolved, rejected) => {
    axios
      .post(`${SERVER_DOMAIN}/upload/all`, value, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        onUploadProgress: (ProgressEvent) => {
          if (callback) {
            callback(Math.trunc((ProgressEvent.loaded / ProgressEvent.total) * 100));
          }
        },
      })
      .then((response) => {
        resolved(response.data);
      })
      .catch((err) => {
        rejected(err);
      });
  });

export function isBrowser() {
  return typeof window !== "undefined";
}

export function replaceImageUrl(url: string): string {
  return url?.replace(process.env.IMAGE_URL!, process.env.SERVER_IMAGE_URL!);
}
