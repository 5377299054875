import React, { Dispatch, useReducer } from "react";
import { PostHogProvider } from "../utils/posthog";
import { ActionProps, InitialStateProps } from "./context.types";
import Reducer, { initialState } from "./reducer";

const StateContext = React.createContext<React.ComponentState>([]);

interface StateProviderProps {
  children: React.ReactNode;
}

export const useStateContext = (): [InitialStateProps, Dispatch<ActionProps>] =>
  React.useContext(StateContext);

const StateProvider: React.FC <StateProviderProps> = (props) => {
  const { children } = props;

  return (
    <StateContext.Provider value={useReducer(Reducer, initialState)}>
      <PostHogProvider>{children}</PostHogProvider>
    </StateContext.Provider>
  );
};

export default StateProvider;
