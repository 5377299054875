import {gql} from '@apollo/client';
import { COMPANY_FRAGMENT, PREMIUM_PACKAGE_HISTORY_FRAGMENT} from '../common';

export const EMPLOYER_PROFILE_COMPLETION_FRAGMENT = gql`
  fragment EmployerProfileCompletion on EmployerProfileCompletion {
    logo
    about
    banner
    companies_profile
    verify
    step_completion
    all_complete
    total_step
  }
`;

export const EMPLOYER_FRAGMENT = gql`
  fragment Employer on Employer {
    id
    name
    email
    phone
    kitakerja_user {
      id
      is_premium
      premium_packages {
        kitakerja_user_id
        ...PremiumPackageHistoryPagination
      }
    }
    company {
      ...Company
    }
    profile_completion {
      ...EmployerProfileCompletion
    }
  }
  ${COMPANY_FRAGMENT}
  ${EMPLOYER_PROFILE_COMPLETION_FRAGMENT}
  ${PREMIUM_PACKAGE_HISTORY_FRAGMENT}
`;

