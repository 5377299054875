import { ActionProps, InitialStateProps } from "./context.types"

export const initialState: InitialStateProps = {
  user: null,
  isAuthorized: false,
  accessToken: null,
  refreshToken: null,
  userProgress: null,
}

const Reducer = (state: InitialStateProps = initialState, action: ActionProps): InitialStateProps => {
  switch (action.type) {
    case 'SET_USER_DATA': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload,
      }
    }

    case 'SET_USER_PROGRESS': {

      const newUserProgress = { ...action.payload }

      const { userProgress } = state;

      let newStepCompletion = 0;

      const blackList = ['total_step', 'all_complete', 'step_completion', '__typename']

      Object.keys(newUserProgress).forEach((key) => {
        if (!blackList.some(str => key === str) && newUserProgress[key]) {
          newStepCompletion++;
        }
      })

      newUserProgress.step_completion = newStepCompletion;

      if (!userProgress?.all_complete && newUserProgress?.step_completion === newUserProgress?.total_step) {
        newUserProgress.all_complete = true;
      }

      return {
        ...state,
        userProgress: newUserProgress,
      }
    }

    case 'SIGN_OUT': {
      return initialState
    }
    
    default: {
      return state
    }
  }
}

export default Reducer