import { gql } from '@apollo/client'
import { MEDIUM_FILE_FRAGMENT } from '../Files'

export const MEDIA_FRAGMENT = gql`
  fragment Media on GalleryMedia {
    id
    file {
      ...MediumFiles
    }
  }
  ${MEDIUM_FILE_FRAGMENT}
`

export const GALLERY_FRAGMENT = gql`
  fragment Gallery on Gallery {
    id
    name
    content {
      ...Media
    }
  }
  ${MEDIA_FRAGMENT}
`

export const GET_GALLERIES = gql`
  query GetCompanyGalleries($companyId: Int!) {
    company {
      company(id: $companyId) {
        id
        galleries {
          galleries {
            ...Gallery
          }
        }
      }
    }
  }
  ${GALLERY_FRAGMENT}
`

export const GET_GALLERY = gql`
  query GetCompanyGallery($companyId: Int!, $galleryId: Int!) {
    company {
      company(id: $companyId) {
        id
        galleries {
          gallery(id: $galleryId) {
            ...Gallery
          }
        }
      }
    }
  }
  ${GALLERY_FRAGMENT}
`

export const CREATE_ALBUM = gql`
  mutation CreateAlbum($companyId: Int!, $input: NewGallery!) {
    company(id: $companyId) {
      gallery {
        create(input: $input) {
          ...Gallery
        }
      }
    }
  }
  ${GALLERY_FRAGMENT}
`;

export const UPDATE_ALBUM = gql`
  mutation UpdateAlbum($companyId: Int!, $input: EditGallery!) {
    company(id: $companyId) {
      gallery {
        update(input: $input) {
          ...Gallery
        }
      }
    }
  }
  ${GALLERY_FRAGMENT}
`;

export const DELETE_ALBUM = gql`
  mutation UpdateAlbum($companyId: Int!, $albumId: Int!) {
    company(id: $companyId) {
      gallery {
        delete(id: $albumId)
      }
    }
  }
`;

export const CREATE_MEDIA = gql`
  mutation CreateMedia($companyId: Int!, $galleryId: Int!, $filePathIds: [Int!]!) {
    company(id: $companyId) {
      gallery {
        media {
          create(
            file_path_ids: $filePathIds,
            gallery_id: $galleryId
          ) {
            ...Media
          }
        }
      }
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const DELETE_MEDIA = gql`
  mutation DeleteMedia($companyId: Int!, $deleteIds: [Int!]!) {
    company(id: $companyId) {
      gallery {
        media {
          delete(
            ids: $deleteIds
          )
        }
      }
    }
  }
`;
