import { gql } from "@apollo/client";
import { EMPLOYER_FRAGMENT } from "../Employer";
import { LIMITED_USER_FRAGMENT } from "../common";

export const MEMBER_PROFILE_COMPLETION_FRAGMENT = gql`
  fragment MemberProfileCompletion on MemberProfileCompletion {
    all_complete
    total_step
    step_completion
    educations
    image
    members_skills
    phone_verify
    profile
    resume
    salary
    summary
    email_verify
  }
`;

export const MEMBER_FRAGMENT = gql`
  fragment Member on Member {
    id
    image
    name
    phone
    email
    address
    birthdate
    summary
    step_completion
    visibility_id
    visibility {
      id
      name
    }
    user_id
    my_wallet
    code_referral
    status
    gender
    countries_id
    expected_salary_lowest
    expected_salary_highest
    first_level_administrative_divisions_id
    second_level_administrative_divisions_id
    image_file {
      path
      default
      sizes {
        small
        medium
        large
      }
    }
    profile_completion {
      ...MemberProfileCompletion
    }
  }
  ${MEMBER_PROFILE_COMPLETION_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    name
    email
    member {
      ...Member
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const GET_USER = gql`
  query GetUser($userId: Int!) {
    user {
      user(id: $userId) {
        ...UserData
        kitakerja_user {
          id
          is_followed
        }
      }
    }
  }
  ${LIMITED_USER_FRAGMENT}
`;

export const KITAKERJA_USER_FRAGMENT = gql`
  fragment KitakerjaUser on KitakerjaUser {
    id
    user_type
    is_private
    is_premium
    is_followed
    user {
      ...User
    }
    employer {
      ...Employer
    }
  }
  ${USER_FRAGMENT}
  ${EMPLOYER_FRAGMENT}
`;

export const GET_USER_DATA = gql`
  query GetUserData {
    me {
      ...KitakerjaUser
    }
  }
  ${KITAKERJA_USER_FRAGMENT}
`;
