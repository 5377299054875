import classnames from "classnames/bind";
import styles from "../../../styles/Layout.module.scss";

const cx = classnames.bind(styles);

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = (props) => {
  const { children } = props;
  return <main className={cx("main")}>{children}</main>;
};

export default Main;
