import Main from "./main";
import { ReactNode, ReactNodeArray, useEffect } from "react";
import useFetch from "use-http";
import { useStateContext } from "../../context";
import { useApollo } from "../../apollo";
import { ApolloProvider, useQuery } from "@apollo/client";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { isBrowser } from "../../utils/common";
import { getClientServerURL } from "../../utils/auth";
import Head from "next/head";
import { useRouter } from "next/dist/client/router";
import { GET_USER_DATA } from "../../graphql/User";
import NoData from "../common/noData";
import Image from "next/image";
import ToastProvider from "@pmberjaya/kitakerja-ui/build/Components/Toast";
import usePosthog from "../../utils/posthog";
import classnames from "classnames/bind";
import styles from "../../styles/Layout.module.scss";

const Header = dynamic(() => import("./header"), { ssr: false });
const Footer = dynamic(() => import("./footer"), { ssr: false });
const WhatsAppButton = dynamic(() => import("../common/whatsappButton"), {
  ssr: false,
});

const cx = classnames.bind(styles);

interface LayoutProps {
  children: ReactNode | ReactNodeArray;
  initialApolloState: any;
  isError?: boolean;
}

const UserController: React.FC = () => {
  const { data } = useQuery(GET_USER_DATA, {
    ssr: false,
  });
  const [_, dispatch] = useStateContext();

  useEffect(() => {
    if (data) {
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          isAuthorized: true,
          user: data?.me?.employer,
        },
      });
      dispatch({
        type: "SET_USER_PROGRESS",
        payload: data?.me?.employer?.profile_completion,
      });
    }
  }, [data]);

  return null;
};

const Layout: React.FC<LayoutProps> = (props) => {
  const { children, initialApolloState, isError } = props;

  const router = useRouter();

  const { t } = useTranslation("common");

  const [{ isAuthorized, user }, dispatch] = useStateContext();
  const { identify, getDistinctId } = usePosthog();

  const { get } = useFetch(getClientServerURL() + "/api/auth");

  useEffect(() => {
    if (user && getDistinctId() != user.id.toString()) {
      identify(user.id.toString(), { email: user.email });
    }
  }, [user]);

  async function getUserData() {
    const result = await get();

    if (result?.status) {
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          isAuthorized: true,
        },
      });
    }

    if (result?.message === "invalid token") {
      router.push("sign-out");
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      getUserData();
    }
  }, []);

  const apolloClient = useApollo(initialApolloState);

  if (isError) {
    return (
      <NoData
        alt={t("internal system error")}
        className="h-100 w-100 position-fixed justify-content-center"
        src="/illustrations/server-down.svg"
      >
        <div className="p-3">
          <Image
            src="/images/kitakerja.svg"
            width="150px"
            height="40px"
            alt="company kitakerja"
          />
        </div>
      </NoData>
    );
  }

  return (
    <>
      <Head>
        <title>Pasang Iklan Lowongan Kerja GRATIS! - Company KitaKerja</title>
      </Head>
      <ApolloProvider client={apolloClient}>
        {isAuthorized && isBrowser() && <UserController />}
        <ToastProvider>
          {/* <div className={cx("root-content")}>
            <div className={cx("root-main")}> */}
          <Header />
          <Main>
            <div
              className={cx("main-content")}
              // style={{ paddingTop: router.pathname === "/" ? 0 : "70px" }}
            >
              {children}
            </div>
            <Footer />
          </Main>
          <WhatsAppButton />
          {/* </div>
          </div> */}
        </ToastProvider>
      </ApolloProvider>
    </>
  );
};

export default Layout;
