import { gql } from '@apollo/client';

export const FILE_FRAGMENT = gql`
  fragment Files on Files {
    id
    default
    path
    downloadable
  }
`;

export const SMALL_FILE_FRAGMENT = gql`
  fragment SmallFiles on Files {
    ...Files
    sizes {
      small
    }
  }
  ${FILE_FRAGMENT}
`;

export const MEDIUM_FILE_FRAGMENT = gql`
  fragment MediumFiles on Files {
    ...Files
    sizes {
      medium
    }
  }
  ${FILE_FRAGMENT}
`;

export const LARGE_FILE_FRAGMENT = gql`
  fragment LargeFiles on Files {
    ...Files
    sizes {
      large
    }
  }
  ${FILE_FRAGMENT}
`;

export const ALL_SIZES_FILE_FRAGMENT = gql`
  fragment AllSizesFiles on Files {
    ...Files
    sizes {
      ...SmallFiles,
      ...MediumFiles,
      ...LargeFiles,
    }
  }
  ${FILE_FRAGMENT}
  ${SMALL_FILE_FRAGMENT}
  ${MEDIUM_FILE_FRAGMENT}
  ${LARGE_FILE_FRAGMENT}
`;