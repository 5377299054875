import { InMemoryCacheConfig } from "@apollo/client";

const cache: InMemoryCacheConfig = {
  typePolicies: {
    GalleryQuery: {
      merge: true,
    },
    SearchQuery: {
      merge: true,
    },
    CompanyQuery: {
      merge: true,
    },
    JobQuery: {
      merge: true,
    },
    PositionQuery: {
      merge: true,
    },
    ApplicationSummary: {
      keyFields: ['job_id']
    },
    InboxQuery: {
      merge: true,
    },
    InboxGroup: {
      merge: true,
    },
    Inbox: {
      merge: true,
    },  
    Member: {
      merge: true,
    },
    InboxPagination: {
      keyFields: ['is_request'],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming = [], { readField }) {

            function getLastMessageCreatedAt(inboxObj: any) {
              return readField('created_at', readField('last_message', readField('messages', inboxObj)))
            }

            const existingIdSet = new Set(
              existing.map((inbox: any) => readField('id', inbox)),
            );
            const newIncomingData = incoming.filter(
              (inbox: any) => !existingIdSet.has(readField('id', inbox)),
            );

            return [
              ...newIncomingData,
              ...existing, 
            ];
          },
        },
      },
    },
    MessagePagination: {
      merge: true,
      keyFields: ['inbox_id'],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming = [], { readField }) {
            const existingIdSet = new Set(
              existing.map((message: any) => readField('id', message)),
            );
            const newIncomingData = incoming.filter(
              (message: any) => !existingIdSet.has(readField('id', message)),
            );

            return [
              ...existing,
              ...newIncomingData
            ].sort(
              (a, b) => {
                const firstMessageDate = new Date(readField('created_at', a) || '');
                const secondMessageDate = new Date(readField('created_at', b) || '');

                if (firstMessageDate < secondMessageDate) return -1;
                return 1;
              }
            );
          },
        },
      },
    },
    PositionPagination: {
      keyFields: [],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((position: any) => readField('id', position)),
            );
            const newIncomingData = incoming.filter(
              (position: any) => !existingIdSet.has(readField('id', position)),
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    PostPagination: {
      keyFields: ['is_me'],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((post: any) => readField('id', post)),
            );
            const newIncomingData = incoming.filter(
              (post: any) => !existingIdSet.has(readField('id', post)),
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    CommentPagination: {
      keyFields: ['post_id'],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, { readField }) {
            const existingIdSet = new Set(
              existing.map((post: any) => readField('id', post)),
            );
            const newIncomingData = incoming.filter(
              (post: any) => !existingIdSet.has(readField('id', post)),
            );

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    FollowerPagination: {
      keyFields: ['name', 'user_type'],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, {readField}) {
            const existingIdSet = new Set(existing.map((follower: any) => readField('id', follower)));
            const newIncomingData = incoming.filter((follower: any) => !existingIdSet.has(readField('id', follower)));

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
    FollowingPagination: {
      keyFields: ['name', 'user_type'],
      fields: {
        nodes: {
          keyArgs: false,
          merge(existing = [], incoming, {readField}) {
            const existingIdSet = new Set(existing.map((following: any) => readField('id', following)));
            const newIncomingData = incoming.filter((following: any) => !existingIdSet.has(readField('id', following)));

            return [...existing, ...newIncomingData];
          },
        },
      },
    },
  }
}

export default cache;