import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
} from "next";
import { serializeCookie, setCookies } from "../cookie";

export async function signOut(res: NextApiResponse) {
  const cookieOptions = {
    maxAge: 0,
    path: "/",
    domain:
      process.env.NODE_ENV === "development"
        ? "localhost"
        : process.env.COOKIE_DOMAIN,
  };
  const cookies = [
    serializeCookie("access_token", null, { ...cookieOptions }),
    // serializeCookie('refresh_token', null, { ...cookieOptions }),
  ];

  await setCookies(res, cookies);
}

export function getClientServerURL() {
  return process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.BASE_URL;
}

export function checkAuthorization(ctx: GetServerSidePropsContext) {
  const { req } = ctx;
  if (!req.cookies.access_token) {
    return {
      redirect: {
        destination: getClientServerURL(),
      },
    };
  }
}
