import Hide from "@pmberjaya/kitakerja-ui/build/Components/Hide";
import Image from "next/image";
import Link from "next/link";
import classnames from "classnames";

interface NoDataProps {
  alt: string;
  src?: string;
  href?: string;
  buttonText?: string;
  className?: string;
  hideImg?: boolean;
  children?: React.ReactNode;
}

const NoData: React.FC<NoDataProps> = (props) => {
  const {
    alt = "-",
    src = "/illustrations/not-found.svg",
    href = "#",
    buttonText,
    className,
    hideImg,
    children,
  } = props;

  const containerCls = classnames(
    className,
    "d-flex flex-column w-100 justify-item-center align-items-center"
  );

  return (
    <div className={containerCls}>
      <Hide on={hideImg}>
        <Image width="150px" height="150px" alt={alt} src={src} />
      </Hide>
      <p className="m-0 mt-3 text-center">{alt}</p>
      <Hide on={!children}>{children}</Hide>
      <Hide on={!href || !buttonText}>
        <Link href={href}>
          <a className="mt-3 btn btn-success btn-sm first-letter-uppercase">
            {buttonText}
          </a>
        </Link>
      </Hide>
    </div>
  );
};

export default NoData;
